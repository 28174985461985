import React from 'react';
import { Container, Typography, Paper, Box, Chip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';

const services = [
  {
    title: "Appliance Troubleshoot and Installation",
    description: "We install appliances such as refrigerators, ovens, and washing machines with care, ensuring full compliance with safety standards.",
    caseScenario: "Installed a smart refrigerator for a family in Virginia Beach, integrating it with their home automation system for enhanced efficiency.",
    tags: ["appliance installation", "home automation", "smart appliances", "troubleshooting", "Virginia Beach"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/ApplianceInstallation.jpg?t=2024-10-19T18%3A11%3A26.171Z"
  },
  {
    title: "Electrical Panel Troubleshoot and Installation",
    description: "Upgrade your home's electrical capacity with a new panel, suitable for modern energy demands and smart home systems.",
    caseScenario: "Upgraded an outdated electrical panel for a growing family, ensuring they could power their home addition safely.",
    tags: ["electrical panel", "home upgrades", "smart home", "energy demands", "installation"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/ElectricalPanelInstallation.jpg?t=2024-10-19T18%3A11%3A38.854Z"
  },
  {
    title: "Fire Alarm Troubleshoot and Installation",
    description: "Ensure your home, business, or restaurant is protected with reliable fire alarm systems. We offer installation, troubleshooting, and maintenance to keep you safe and compliant with safety regulations.",
    caseScenario: "Installed a modern fire alarm system in a commercial space, replacing outdated units and ensuring compliance with fire safety codes. Troubleshot faulty sensors in an existing system, restoring full functionality and peace of mind.",
    tags: ["fire alarm", "safety compliance", "troubleshooting", "business", "home security"],
    image: "/assets/images/FireAlarm.webp"
  },
  {
    title: "EV Charging Station Troubleshoot and Installation",
    description: "Convenient and efficient installation of electric vehicle charging stations at home or work.",
    caseScenario: "Installed a Level 2 EV charger for a Tesla Model 3 owner, reducing their charging time by 50%.",
    tags: ["EV charging", "electric vehicles", "charger installation", "Tesla", "troubleshooting"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/EvChargingStationInstallation.jpg?t=2024-10-19T18%3A11%3A50.721Z"
  },
  {
    title: "AC and Heater Troubleshooting",
    description: "Stay comfortable year-round with expert AC and heater troubleshooting services to ensure optimal performance and energy efficiency.",
    caseScenario: "Diagnosed and repaired a malfunctioning thermostat causing inconsistent heating. Troubleshot and fixed an AC unit’s cooling issue by replacing a faulty compressor, ensuring consistent airflow and comfortable indoor temperatures.",
    tags: ["AC", "heater", "troubleshooting", "energy efficiency", "HVAC repair"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/HeaterInstallation.jpeg?t=2024-10-19T18%3A12%3A02.272Z"
  },
  {
    title: "Lighting Troubleshoot and Installation",
    description: "We provide indoor and outdoor lighting solutions to brighten up your space.",
    caseScenario: "Installed ambient lighting in a home theater for a fully immersive viewing experience.",
    tags: ["lighting installation", "ambient lighting", "home theater", "outdoor lighting", "troubleshooting"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/LightingInstallation.jpeg?t=2024-10-19T18%3A12%3A12.038Z"
  },
  {
    title: "Outlet Troubleshoot and Installation",
    description: "Add new outlets or upgrade existing ones to accommodate modern devices safely.",
    caseScenario: "Upgraded all outlets to include USB ports in a tech-heavy household.",
    tags: ["outlet installation", "USB outlets", "electrical upgrades", "home improvement", "safe wiring"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/OutletInstallation.jpeg?t=2024-10-19T18%3A12%3A22.722Z"
  },
  {
    title: "Smart Home Automation",
    description: "Control your home’s lighting, climate, and security with advanced smart technologies.",
    caseScenario: "Integrated voice-controlled smart lighting and thermostat into a newly built home.",
    tags: ["smart home", "home automation", "voice control", "smart thermostat", "security automation"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/SmartHomeAutomationInstallation.jpeg?t=2024-10-19T18%3A12%3A36.856Z"
  },
  {
    title: "Energy Audits",
    description: "Reduce your energy costs by assessing your energy consumption patterns.",
    caseScenario: "Performed an energy audit for a commercial office, cutting their annual energy expenses by 25%.",
    tags: ["energy efficiency", "energy audit", "cost savings", "commercial energy", "sustainable living"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/EnergyAuditService.jpeg?t=2024-10-19T18%3A12%3A49.506Z"
  },
  {
    title: "Backup Power Solutions",
    description: "Never lose power during outages with our reliable backup generator solutions.",
    caseScenario: "Installed a whole-house backup generator system for a family in a storm-prone area.",
    tags: ["backup generator", "power solutions", "storm preparation", "energy security", "home generator"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/BackupPowerSolutionsService.jpeg?t=2024-10-19T18%3A13%3A01.739Z"
  },
  {
    title: "Pool and Spa Electrical Wiring",
    description: "Ensure your pool and spa have safe and reliable electrical systems.",
    caseScenario: "Installed specialized wiring for a newly built outdoor pool and spa area with automatic heating and filtration controls.",
    tags: ["pool wiring", "spa wiring", "outdoor wiring", "filtration systems", "safe electrical systems"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/PoolSpaWiringService.jpeg?t=2024-10-19T18%3A13%3A11.423Z"
  },
  {
    title: "Electrical Safety Inspections",
    description: "Keep your home safe by scheduling regular electrical safety inspections.",
    caseScenario: "Conducted an inspection in an older home, identifying and resolving several fire hazards.",
    tags: ["electrical safety", "home inspection", "fire hazards", "safety compliance", "regular inspections"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/ElectricalInspectionService.jpeg?t=2024-10-19T18%3A13%3A25.957Z"
  },
  {
    title: "Surge Protection Systems",
    description: "Protect your home from damaging power surges with advanced protection systems.",
    caseScenario: "Installed whole-house surge protection for a homeowner worried about frequent lightning storms.",
    tags: ["surge protection", "whole-house surge", "lightning protection", "power surge", "electrical safety"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/SurgeProtectionService.jpeg?t=2024-10-19T18%3A13%3A41.224Z"
  },
  {
    title: "CCTV and Video Surveillance",
    description: "Install state-of-the-art video surveillance to keep your home or business safe.",
    caseScenario: "Set up a high-definition CCTV system for a small business, covering key entry points and parking areas.",
    tags: ["CCTV", "video surveillance", "home security", "business security", "high-definition cameras"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/SecurityCameraService.jpeg?t=2024-10-19T18%3A13%3A55.340Z"
  },
  {
    title: "Access Control Systems",
    description: "Manage who enters your building with customizable access control systems.",
    caseScenario: "Installed a biometric access control system for an office building, improving security.",
    tags: ["access control", "biometric security", "building security", "custom access", "office security"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/AccessControlService.jpeg?t=2024-10-19T18%3A14%3A09.007Z"
  },
  {
    title: "Home Theater Troubleshoot and Installation",
    description: "Transform any room into a cinema with custom home theater installations.",
    caseScenario: "Installed a state-of-the-art home theater system with surround sound and a 4K projector.",
    tags: ["home theater", "4K projector", "surround sound", "home entertainment", "custom installations"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/HomeTheatreInstallation.jpeg?t=2024-10-19T18%3A14%3A20.024Z"
  },
  {
    title: "Smart Thermostat Troubleshoot and Installation",
    description: "Install and configure smart thermostats to save on energy costs.",
    caseScenario: "Replaced old thermostats with smart ones that sync with a mobile app for remote control.",
    tags: ["smart thermostat", "energy savings", "climate control", "remote control", "HVAC system"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/SmartThermostatInstallation.jpeg?t=2024-10-19T18%3A14%3A30.925Z"
  },
  {
    title: "Portable Generator Setup",
    description: "Set up portable generators for emergency power solutions.",
    caseScenario: "Set up a portable generator system for a rural homeowner, ensuring they always have power during outages.",
    tags: ["portable generator", "emergency power", "outages", "rural power", "generator installation"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/PortableGeneratorService.jpeg?t=2024-10-19T18%3A14%3A42.075Z"
  },
  {
    title: "Data Cabling and Networking",
    description: "Structured cabling solutions for homes and businesses to enhance network reliability.",
    caseScenario: "Installed advanced network cabling in a new office, ensuring fast and reliable internet connectivity throughout the building.",
    tags: ["data cabling", "networking", "structured cabling", "internet connectivity", "office networking"],
    image: "https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/DataCableService.jpeg?t=2024-10-19T18%3A14%3A54.192Z"
  },
  
];

const ServiceList = () => {
  const theme = useTheme();


  return (
    <Container
      sx={{
        paddingY: theme.spacing(8),
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        id="services"
        component="img"
        src="/assets/images/TitleImage.png"
        alt="Virginia Beach Electrician - Scott's Electric - Our Services"
        sx={{
          width: 'auto',
          height: { xs: '150px', md: '250px' },
          border: '1px solid #FFD700',
          boxShadow: '10px 0px 10px #000',
          borderTopLeftRadius: 10,
          borderBottomRightRadius: 10,
          display: 'block',
          margin: '0 auto',
          marginBottom: theme.spacing(4),
        }}
      />

      <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
        {services.map((service, index) => (
          <Grid
            item="true"
            size={{ xs: 12 }}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Paper
              elevation={10}
              sx={{
                width: { md: '550px', xs: '90vw' },
                padding: theme.spacing(4),
                textAlign: 'center',
                background: 'linear-gradient(145deg, #1e1e1e, #2a2a2a)',
                borderRadius: theme.shape.borderRadius * 2,
                color: '#eee',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.6)',
                '&:hover': {
                  boxShadow: '0 16px 40px rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <Box
                component="img"
                alt={`Virginia Beach Electrician Services - ${service.title}`}

                src={service.image}
                sx={{
                  width: '100%',
                  height: 'auto',
                  backgroundColor: theme.palette.grey[800],
                  borderRadius: theme.shape.borderRadius,
                  marginBottom: theme.spacing(2),
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                  color: '#FFD700',
                  marginBottom: theme.spacing(1),
                }}
              >
                {service.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1rem',
                  marginBottom: theme.spacing(2),
                  fontWeight: 600,
                }}
              >
                {service.description}
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  fontStyle: 'italic',
                  color: '#ccc',
                  marginBottom: theme.spacing(2),
                }}
              >
                {service.caseScenario}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {service.tags.map((tag, idx) => (
                  <Chip
                    key={idx}
                    label={tag}
                    sx={{
                      background:
                        'linear-gradient(45deg, #6a11cb, #2575fc)',
                      color: '#fff',
                      fontWeight: 'bold',
                      paddingX: theme.spacing(1),
                      borderRadius: '8px',
                      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background:
                          'linear-gradient(45deg, #2575fc, #6a11cb)',
                        transform: 'scale(1.1)',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.8)',
                      },
                    }}
                  />
                ))}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServiceList;