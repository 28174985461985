// src/components/CoinModel.js
import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import * as THREE from 'three';

const CoinModel = () => {
  const { scene } = useGLTF('https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/glbs/ScottsElectricLogo.glb?t=2024-10-19T18%3A15%3A12.075Z');
  const texture = useTexture('https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/images/scottselectriclogo2.png?t=2024-10-19T18%3A15%3A26.176Z');
  const meshRef = useRef();

  scene.traverse((child) => {
    if (child.isMesh) {
      child.material = new THREE.MeshStandardMaterial({
        color: 'white',
        map: texture,
      });
    }
  });

  return <primitive ref={meshRef} object={scene} scale={1.5} position={[0, 0, 0]} />;
};

const CoinModelCanvas = () => {
  return (
    <Canvas style={{ width: '100%', height: '400px' }}>
      <ambientLight intensity={0.5} />
      <directionalLight position={[0, 10, 5]} intensity={1} />
      <CoinModel />
    </Canvas>
  );
};

export default CoinModelCanvas;
