import React, { useState } from 'react';
import { Box, Typography, Button, Chip, Modal, TextField, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import VerifiedIcon from '@mui/icons-material/Verified';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import BusinessIcon from '@mui/icons-material/Business';
import ConstructionIcon from '@mui/icons-material/Construction';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import { TypeAnimation } from 'react-type-animation';
import { Link as ScrollLink } from 'react-scroll';

const HeroSection = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <Box
      sx={{
        paddingTop: '120px',
        position: 'relative',
        minHeight: '90vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
        justifyContent: 'center',
      }}
      id="about"
    >
      {/* Background Video */}
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src="https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/videos/DesktopHeroVideo.mp4?t=2024-10-19T18%3A16%3A07.043Z" media="(min-width: 768px)" type="video/mp4" />
        <source src="https://lgtmxdnpnbcnahfeayfb.supabase.co/storage/v1/object/public/Website%20Media/assets/videos/MobileHeroVideo.mp4?t=2024-10-19T18%3A16%3A22.510Z" media="(max-width: 767px)" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay Content */}
      <Box
        sx={{
          zIndex: 1,
          padding: theme.spacing(4),
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#FFD700',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '2px',
            marginBottom: theme.spacing(2),
          }}
        >
          <TypeAnimation
            sequence={[
              'Welcome to',
              1000,
              'Scott\'s Electric',
              2000,
              'Hampton Roads',
              1000,
              'Virginia (757)',
              2000,
            ]}
            speed={50}
            wrapper="span"
            repeat={Infinity}
          />
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            maxWidth: '600px',
            margin: '0 auto',
            marginBottom: theme.spacing(4),
            fontSize: '1.2rem',
            lineHeight: 1.6,
          }}
        >
          Your trusted provider for <strong>safe</strong>, <strong>reliable</strong>, and <strong>efficient</strong> electrical services in Hampton Roads. Let us power your home and business with expertise, dedication, and a commitment to excellence.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: theme.spacing(2),
            marginBottom: theme.spacing(4),
            flexWrap: 'wrap',
          }}
        >
          <Chip icon={<ElectricBoltIcon />} label="Expert Electricians" color="secondary" sx={{ fontWeight: 'bold' }} />
          <Chip icon={<VerifiedIcon />} label="Trusted & Certified" color="primary" sx={{ fontWeight: 'bold' }} />
          <Chip icon={<HomeRepairServiceIcon />} label="Residential Services" color="success" sx={{ fontWeight: 'bold' }} />
          <Chip icon={<BusinessIcon />} label="Commercial Services" sx={{ fontWeight: 'bold', backgroundColor: 'white' }} />
          <Chip icon={<ConstructionIcon />} label="New Construction" color="warning" sx={{ fontWeight: 'bold' }} />
          <Chip icon={<UpdateIcon />} label="Old Construction" color="error" sx={{ fontWeight: 'bold' }} />
        </Box>

        <Box display="flex" gap={2} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '50px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
            onClick={handleOpen}
          >
            Request a Quote
          </Button>

          <ScrollLink to="contact" smooth={true} duration={500} offset={-70}>
            <Button
              variant="outlined"
              size="large"
              sx={{
                borderColor: theme.palette.primary.contrastText,
                borderRadius: '50px',
                textTransform: 'none',
                '&:hover': {
                  borderColor: theme.palette.secondary.main,
                },
              }}
            >
              Request Service
            </Button>
          </ScrollLink>
        </Box>
      </Box>

      {/* Quote Request Modal */}
      {/* Quote Request Modal */}
<Modal open={openModal} onClose={handleClose}>
  <Box
    component="form"
    name="quote-request" // Name for Netlify form
    method="POST"
    data-netlify="true"
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'white',
      borderRadius: '10px',
      boxShadow: 24,
      p: 4,
    }}
  >
    {/* Hidden field for Netlify */}
    <input type="hidden" name="form-name" value="quote-request" />

    <IconButton
      onClick={handleClose}
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon />
    </IconButton>
    <Box 
    component="img"
    src="/assets/images/scottselectriclogo2.png"
    sx={{height: 200, width: 200}}
    />
    <Typography variant="h6" sx={{ mb: 2 }}>
      Request a Quote Today!
    </Typography>
<Typography variant='body'>(please be descriptive)</Typography>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth label="Name" name="name" variant="outlined" />
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label="Budget" name="budget" variant="outlined" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Problem Description"
          name="problem-description"
          multiline
          rows={4}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Start Date"
          name="start-date"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Finish Date"
          name="finish-date"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  </Box>
</Modal>

    </Box>
  );
};

export default HeroSection;
