import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const ThankYou = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    // Countdown timer
    const interval = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    // Redirect after 10 seconds
    const timeout = setTimeout(() => {
      navigate("/");
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#0f0f0f",
        textAlign: "center",
        color: "white",
        padding: "2rem",
      }}
    >
      {/* Confetti on load */}
      <Confetti width={width} height={height} numberOfPieces={400} />

      <Typography variant="h2" sx={{ fontWeight: "bold", marginBottom: 2 }}>
        🎉 Thank You! 🎉
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: 4 }}>
        We appreciate your interest! One of our team members will contact you shortly.
      </Typography>

      <Typography variant="h6" sx={{ marginBottom: 4 }}>
        Redirecting you in <strong>{countdown}</strong> seconds...
      </Typography>

      <Button
        variant="contained"
        onClick={() => navigate("/")}
        sx={{
          backgroundColor: "#B3AD1F",
          color: "black",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#e0c92f",
          },
        }}
      >
        Go to Home Now
      </Button>
    </Box>
  );
};

export default ThankYou;
