// src/components/Footer.js
import React from 'react';
import { Container, Typography, Box, IconButton, Link, Divider, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        background: 'linear-gradient(135deg, #1c1c1e, #111)',
        color: '#e0e0e0',
        padding: { xs: '2rem 1rem', md: '3rem 0' },
        marginTop: 'auto',
        boxShadow: '0px -3px 15px rgba(0, 0, 0, 0.5)',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {/* Column 1: Logo and Contact Info */}
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <img
              src="/assets/images/scottselectriclogo2.png"
              alt="Scott's Electric Logo"
              style={{ width: '150px', marginBottom: '1rem', borderRadius: '50%' }}
            />
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, fontWeight: 700, color: '#fff' }}
            >
              Connect With Us Today
            </Typography>
            <Link
              href="mailto:contact@scottselectric.pro"
              sx={{ color: '#a8a8a8', fontSize: '1rem', display: 'block', mt: 1 }}
            >
              contact@scottselectric.pro
            </Link>
            <Link
              href="tel:+17574355617"
              sx={{ color: '#a8a8a8', fontSize: '1rem', mt: 1 }}
            >
              +1 (757) 435-5617
            </Link>
          </Grid>

          {/* Column 2: Social Icons */}
          <Grid item xs={12} md={4} textAlign="center">
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', flexWrap: 'wrap' }}>
              <IconButton
                component={Link}
                href="mailto:contact@scottselectric.pro"
                sx={iconButtonStyles}
                aria-label="Email"
              >
                <EmailIcon sx={{ fontSize: 28 }} />
              </IconButton>

              <IconButton
                component={Link}
                href="tel:+17574355617"
                sx={iconButtonStyles}
                aria-label="Phone"
              >
                <PhoneIcon sx={{ fontSize: 28 }} />
              </IconButton>

              <IconButton
                component={Link}
                href="https://www.yelp.com/biz/scotts-electrical-service-virginia-beach"
                target="_blank"
                rel="noopener"
                sx={iconButtonStyles}
                aria-label="Yelp"
              >
                <img
                  src="/assets/images/yelp.png"
                  alt="Yelp"
                  style={{ width: '28px', height: '28px' }}
                />
              </IconButton>

              <IconButton
                component={Link}
                href="https://instagram.com/scottselectricva"
                target="_blank"
                rel="noopener"
                sx={iconButtonStyles}
                aria-label="Instagram"
              >
                <Instagram sx={{ fontSize: 30 }} />
              </IconButton>

              <IconButton
                component={Link}
                href="https://linkedin.com/company/scotts-electric-va"
                target="_blank"
                rel="noopener"
                sx={iconButtonStyles}
                aria-label="LinkedIn"
              >
                <LinkedIn sx={{ fontSize: 30 }} />
              </IconButton>
            </Box>
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider
              sx={{
                backgroundColor: '#555',
                margin: '2rem 0',
                borderBottomWidth: '2px',
              }}
            />
          </Grid>

          {/* Column 3: All Rights Reserved */}
          <Grid item xs={12} textAlign="center">
            <Typography variant="body2" sx={{ fontSize: '0.9rem', color: '#777', mt: 3 }}>
              &copy; 2024 Scott's Electric. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const iconButtonStyles = {
  color: '#e0e0e0',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  padding: '0.7rem', // Reduced padding for smaller size
  borderRadius: '50%',
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 215, 0, 0.8)',
    transform: 'scale(0.92)',
  },
};

export default Footer;
